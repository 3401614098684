import "../BuyNow.css"

export default function Testimonial(){
return(
    <section className="Testimonial">
        <h1>"This banana cured my cancer"</h1>
        <h2>-Steve Jobs</h2>
        <h6>
            <i>This is not medical or financial advise for legal reasons</i>
        </h6>
    </section>
)
}