import "../HomeSection.css"
import BananasChart from "./Chart"

export default function Section5(){
    return(
        <section className="Section5">   
            <h1>Favorite Fruits</h1>
            <BananasChart/>
        </section>
    )
}
